var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-4"},[_c('p',{staticClass:"text-h5 white--text"},[_vm._v("จัดการสมาชิก")])])],1),_c('v-row',[_c('CardReport1',{attrs:{"title":'สมาชิกทั้งหมด',"number":_vm.total,"showPercent":false,"avatar":'/icon/move-vai.jpg'}}),_c('CardReport1',{attrs:{"title":'กำลังใช้งาน',"number":_vm.activeNumber,"showPercent":false,"avatar":'/icon/user_green.png'}}),_c('CardReport1',{attrs:{"title":'ระงับการใช้งาน',"number":_vm.suspendedNumber,"showPercent":false,"avatar":'/icon/user_green.png'}})],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%","border":"0.5px solid #aaaaaa"},attrs:{"loading":_vm.isLoading,"loading-text":"กำลังโหลด....... กรุณารอซักครู่","headers":_vm.headers,"items-per-page":30,"footer-props":{
          itemsPerPageOptions: [ 0, 30 ],
          disableItemsPerPage: true
      },"items":_vm.data,"server-items-length":_vm.total,"options":_vm.optionsTable,"show-select":"","no-data-text":"ไม่พบข้อมูล","dark":""},on:{"update:options":function($event){_vm.optionsTable=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('p',{staticClass:"text-h6 ma-2 white--text"},[_vm._v("ผู้ใช้งานระบบทั้งหมด "),_c('span',{staticClass:"yellow--text text--darken-3"},[_vm._v(" "+_vm._s(_vm.total)+" คน ")])]),_c('div',{staticClass:"d-flex pa-3"},[_c('div',{staticClass:"mr-auto"},[_c('v-text-field',{attrs:{"dense":"","label":"ค้นหารายการ"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getListCustomers.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-icon',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" mdi-magnify ")])],1)],1)])]},proxy:true},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"d-block grey--text"},[_vm._v(_vm._s(item.email))])]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))]),_c('span',{staticClass:"d-block grey--text"},[_vm._v("ใช้งานล่าสุด "+_vm._s(_vm.formatDate(item.updatedAt)))])]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.type == 'person' ? 'บุคคลธรรมดา' : item.type == 'corporation' ? 'นิติบุคคล' : '')+" "),_c('span',{staticClass:"d-block grey--text"},[_vm._v(_vm._s(item.companyName))])])]}},{key:"item.mobile",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.transformMobileToText(item.mobile)))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",class:['กำลังใช้งาน' == item.status ? 'green--text' : 'grey--text'],attrs:{"outlined":""}},[_c('v-icon',{class:'green--text',attrs:{"left":""}},[_vm._v(" mdi-circle-medium ")]),_vm._v(" กำลังใช้งาน ")],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('alert-error',{ref:"alertError",attrs:{"text":_vm.errorMessage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }