<template>
    <v-container>
      <v-row>
        <v-col class="mt-4">
          <p class="text-h5 white--text">จัดการสมาชิก</p>
        </v-col>
      </v-row>
      <v-row>
        <CardReport1 :title="'สมาชิกทั้งหมด'" :number="total" :showPercent="false" :avatar="'/icon/move-vai.jpg'"/>
        <CardReport1 :title="'กำลังใช้งาน'" :number="activeNumber" :showPercent="false" :avatar="'/icon/user_green.png'"/>
        <CardReport1 :title="'ระงับการใช้งาน'" :number="suspendedNumber" :showPercent="false" :avatar="'/icon/user_green.png'"/>
      </v-row>
      <v-row>
          <v-data-table
          :loading="isLoading"
          loading-text="กำลังโหลด....... กรุณารอซักครู่"
          v-model="selected"
          :headers="headers"
          :items-per-page="30"
          :footer-props="{
              itemsPerPageOptions: [ 0, 30 ],
              disableItemsPerPage: true
          }"
          :items="data"
          :server-items-length="total"
          :options.sync="optionsTable"
          class="elevation-1"
          style="width: 100%; border: 0.5px solid #aaaaaa;"
          show-select
          no-data-text="ไม่พบข้อมูล"
          dark
          >
            <template v-slot:top>
              <p class="text-h6 ma-2 white--text">ผู้ใช้งานระบบทั้งหมด  <span class="yellow--text text--darken-3"> {{ total }} คน </span> </p>
              <div class="d-flex pa-3">
                    <div class="mr-auto">
                        <v-text-field @keydown.enter="getListCustomers" v-model="search" dense label="ค้นหารายการ">
                            <v-icon
                            slot="prepend"
                            >
                            mdi-magnify
                            </v-icon>
                        </v-text-field>
                    </div> 
              </div>
            </template>
             <template v-slot:[`item.name`]="{ item }">
              <span>{{ item.name }}</span>
              <span class="d-block grey--text">{{ item.email }}</span>
            </template>
             <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ formatDate(item.createdAt) }}</span>
              <span class="d-block grey--text">ใช้งานล่าสุด {{ formatDate(item.updatedAt) }}</span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <span class="text-capitalize">
                {{ item.type == 'person' ? 'บุคคลธรรมดา' : item.type == 'corporation' ? 'นิติบุคคล' : '' }}
                <span class="d-block grey--text">{{ item.companyName }}</span>
              </span>
            </template>
            <template v-slot:[`item.mobile`]="{ item }">
               <span> {{ transformMobileToText(item.mobile) }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                outlined
                class="ma-2"
                :class="['กำลังใช้งาน' == item.status ? 'green--text' : 'grey--text']"
              >
                <v-icon left :class="'green--text'">
                  mdi-circle-medium
                </v-icon>
                กำลังใช้งาน
              </v-chip>
            </template>
          </v-data-table>
      </v-row>
      <alert-error :text="errorMessage" ref="alertError" />
    </v-container>
</template>

<script>
import CardReport1 from '../../components/CardReport1.vue';
import { getCustomers } from '../../models/customer';
import AlertError from '../../components/AlertError.vue';
import moment from 'moment';

export default {
      data: () => ({
    //
    search: '',
    singleSelect: false,
    selected: [],
    headers: [
      { text: 'ชื่อ - นามสกุล', value: 'name', sortable: false },
      { text: 'ประเภทสมาชิก', value: 'type', sortable: false },
      { text: 'สร้างวันที่', value: 'createdAt', sortable: false },
      { text: 'เบอร์โทร', value: 'mobile', sortable: false  },
      { text: 'สถานะการใช้งาน', value: 'status', sortable: false }
    ],
    data: [],
    isLoading: false,
    errorMessage: '',
    total: 0,
    downloadCSVButton: false,
    activeNumber: 0,
    suspendedNumber: 0,
    optionsTable: {}
  }),
  methods: {
    async getListCustomers(){
      try{
        this.isLoading =true;
        const { page } = this.optionsTable;
        const { customers, total } = await getCustomers(this.search, page);
        this.data = customers;
        this.total = total;
        this.activeNumber = total;
        this.suspendedNumber = 0;
      }catch(error){
        this.errorMessage = error.response?.data || error.message;
        this.$refs.alertError.show();
      }finally{
        this.isLoading = false;
      }
    },
    formatDate(date){
      return date ? moment(date).format('DD-MM-YY') : '';
    },
    async getSummary(){
        // TO-DO: In the future
    //   try{
    //     const response = await axios.get(process.env.VUE_APP_BASE_URL + '/users/summary');
    //     this.activeNumber = response.data.active;
    //     this.suspendedNumber = response.data.suspended;
    //   }catch(error){
    //     this.errorMessage = error.response?.data || error.message;
    //     this.$refs.alertError.show();
    //   }finally{
    //     this.isLoading = false;
    //   }
    },
    transformMobileToText(mobile){
        return mobile.substring(0, 3) + '-' + mobile.substring(3, mobile.length);
    }
  },
  async mounted(){
    await this.getListCustomers();
  },
  watch: {
    optionsTable: {
        handler(){
            this.getListCustomers();
        },
        deep: true
    }
  },
  components: {
    CardReport1,
    AlertError
  }
};
</script>