<template>
    <v-card min-height="80" min-width="220" outlined class="d-flex flex-row mr-4 mb-4 rounded-xl" style="background-color: #242730;">
        <v-list-item two-line>
            <v-list-item-avatar
                tile
                size="45"
            >
                <v-avatar v-if="avatar" color="grey">
                    <img :src="avatar">
                </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-subtitle class="grey--text text--lighten-2">{{title}}</v-list-item-subtitle>
                <v-list-item-title class="text-h5 mb-1 white--text font-weight-bold">
                {{number}}
                </v-list-item-title>
            </v-list-item-content>
            <p v-show="showPercent" 
                class="text-caption mt-n4 white--text" 
                :class="{ 'green--text': percent > 0, 'ref--text' : percent < 0 }" >
                {{ percent > 0 ? `+${percent}`: `-${percent}` }}%
            </p>
        </v-list-item>
    </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'รายการทั้งหมด'
        },
        avatar: {
            type: String
        },
        number: {
            type: Number,
            default: 2000
        },
        percent: {
            type: Number,
            default: 20
        },
        showPercent: {
            type: Boolean,
            default: true
        }

    }
};
</script>