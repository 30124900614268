import axios from 'axios';

export async function getCustomers (text = '', page = 1) {
    const response = await axios.get(process.env.VUE_APP_BASE_URL + '/users/customers', { params: { page: page, text: text } });
    return { customers: response.data.customers, total: response.data.total };
}

export async function getUserById (id) {
    const response = await axios.get(process.env.VUE_APP_BASE_URL + '/users/customers/' + id);
    return response.data.user;
}